import React, { useState } from "react";
import { Link } from "react-scroll";
import "../styling/Navbar.css";
import Logo from "../assets/logo.png";

const NavbarComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <div className="header">
      <div className="navbar">
        <div className="navbar__wrapper">
          <nav className="navbar__menu">
            <Link
              className="navbar__brand"
              to="home"
              spy={true}
              smooth={true}
              duration={500}
              style={{ width: "15%", height: "200%" }}>
              <img
                src={Logo}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </Link>
            <div id="nav-icon3" onClick={handleToggle}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul className="navbar__nav">
              <li className="navbar__link after-transform">
                <Link
                  className={isOpen ? "active" : ""}
                  to="about"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={handleLinkClick}>
                  Su di Me
                </Link>
              </li>
              <li className="navbar__link after-transform">
                <Link
                  to="services"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={handleLinkClick}>
                  Servizi
                </Link>
              </li>
              <li className="navbar__link after-transform">
                <Link
                  to="specializations"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={handleLinkClick}>
                  Specializzazioni
                </Link>
              </li>
              <li className="navbar__link after-transform">
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={handleLinkClick}>
                  Dove mi Trovi
                </Link>
              </li>
            </ul>
            <li className="contact mobile-visible after-transform">
              <a
                href="mailto:luigi.collivasone@gmail.com"
                className="contact1 "
                onClick={handleLinkClick}>
                CONTATTAMI
              </a>
            </li>
          </nav>
        </div>
      </div>
      <div
        className="navbar-responsive"
        style={{
          transform: isOpen
            ? "translate3d(0, 0, 0)"
            : "translate3d(-100%, 0, 0)",
        }}>
        <ul className="navbar-responsive__nav">
          <li className="navbar-responsive__link after-transform">
            <Link
              className={isOpen ? "active" : ""}
              to="about"
              spy={true}
              smooth={true}
              duration={500}
              onClick={handleLinkClick}>
              Su di Me
            </Link>
          </li>
          <li className="navbar-responsive__link after-transform">
            <Link
              to="services"
              spy={true}
              smooth={true}
              duration={500}
              onClick={handleLinkClick}>
              Servizi
            </Link>
          </li>
          <li className="navbar-responsive__link after-transform">
            <Link
              to="specializations"
              spy={true}
              smooth={true}
              duration={500}
              onClick={handleLinkClick}>
              Specializzazioni
            </Link>
          </li>
          <li className="navbar-responsive__link after-transform">
            <Link
              to="contact"
              spy={true}
              smooth={true}
              duration={500}
              onClick={handleLinkClick}>
              Dove mi Trovi
            </Link>
          </li>
          <li className="navbar-grhy 7yresponsive__link after-transform">
            <a
              href="mailto:luigi.collivasone@gmail.com"
              onClick={handleLinkClick}>
              Contattami
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavbarComponent;
