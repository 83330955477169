import React from "react";
import "../styling/Contact.css";
import IconStars from "../assets/star.png";

const Contact = () => {
  return (
    <div className="contact-section">
      <div class="contact-heading">
        <div class="contatti">
          <span>
            <span class="contatti-span">Contatti</span>
            <span class="contatti-span2">.</span>
          </span>
        </div>
      </div>
      <div className="infos">
        <div class="luigi-collivasone-gmail-com">
          <a href="mailto:luigi.collivasone@gmail.com">
            luigi.collivasone@gmail.com
          </a>
        </div>
        <div class="_39-3920234632">
          <a href="tel:00393920234632">+39 3920234632</a>
        </div>
        <div class="studio-privato-in-via-torretta-7-pavia">
          <a href="https://maps.app.goo.gl/EYFKN1RzX1LLwtDNA">
            Studio privato in
            <br />
            via Torretta 7, Pavia
          </a>
        </div>
        <div class="review-google">
          <a href="https://g.page/r/CUg-CIy7OtS6EB0/review">
            {" "}
            Apprezzerei molto se lasciassi una recensione su Google!{" "}
            <img className="stars" src={IconStars} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
