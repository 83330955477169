import React from "react";
import "../styling/About.css";
import FotoAbout from "../assets/fotoabout.png";

const About = () => {
  return (
    <div class="about-me">
      <div class="about-heading">
        <div class="su-di-me-container">
          <span>
            <span class="su-di-me-span">Su di me</span>
            <span class="su-di-me-span2">.</span>
          </span>
        </div>
        <div class="description-aboutpage">
          Psicologo, Psicoterapeuta, esperto di Mindfulness e Psicosomatica e
          PNEI. Ricevo a Pavia, Milano e online. Svolgo attività di ricerca nel
          campo dell&#039;infertilità e degli attacchi di panico. Mi occupo
          clinicamente di disturbi d&#039;ansia e dell&#039;umore.
        </div>
      </div>
        <img
          src={FotoAbout}
          alt="Luigi Collivasone standing"
          className="image-about-centered"
        />
    </div>
  );
};

export default About;
