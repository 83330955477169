import React from "react";
import "../styling/Specializations.css";

const Specializations = () => {
  return (
    <div className="specializations-container">
      <div className="title-section">
        <div class="specializzazioni">
          <span>
            <span class="specializzazioni-span">Specializzazioni</span>
            <span class="specializzazioni-span2">.</span>
          </span>
        </div>

        <div class="description-specializations">
          Psicologo, Psicoterapeuta, esperto di Mindfulness e Psicosomatica e
          PNEI. Ricevo a Pavia, Milano e online. Svolgo attività di ricerca nel
          campo dell&#039;infertilità e degli attacchi di panico. Mi occupo
          clinicamente di disturbi d&#039;ansia e dell&#039;umore.
        </div>
      </div>
      <div className="container-cards">
        <div className="card-specialization">
          <h2 className="title-card">Ansia e Attacchi di Panico</h2>
          <p className="description-card-spe">
            Integrazione mente-corpo: comprendi e modula la risposta psicologica
            al fine di potenziare il sistema immunitario.
          </p>
        </div>

        <div className="card-specialization">
          <h2 className="title-card">Mindfulness</h2>
          <p className="description-card-spe">
            Coltiva il mindfulness del momento presente, alleviando lo stress e
            promuovendo la tranquillità interiore.
          </p>
        </div>

        <div className="card-specialization">
          <h2 className="title-card">
            ACT - Acceptance and Commitment Therapy
          </h2>
          <p className="description-card-spe">
            Guida verso il mindfulness, accettazione e azione per superare
            ostacoli e vivere una vita significativa.
          </p>
        </div>

        <div className="card-specialization">
          <h2 className="title-card">
            PNEI - Psico neuroendocrino immunologia
          </h2>
          <p className="description-card-spe">
            Integrazione mente-corpo: comprendi e modula la risposta psicologica
            al fine di potenziare il sistema immunitario.
          </p>
        </div>

        <div className="card-specialization">
          <h2 className="title-card">Psicosomatica</h2>
          <p className="description-card-spe">
            Esplora il legame mente-corpo, comprendendo e trattando le
            manifestazioni fisiche delle tensioni psicologiche.
          </p>
        </div>

        <div className="card-specialization">
          <h2 className="title-card">Psicooncologia</h2>
          <p className="description-card-spe">
            Sostegno emotivo per affrontare le sfide psicologiche legate alla
            diagnosi e al trattamento del cancro.
          </p>
        </div>

        <div className="card-specialization">
          <h2 className="title-card">CFT - Focused Compassion Therapy</h2>
          <p className="description-card-spe">
            Crescita personale attraverso l'auto-compassione focalizzata,
            migliorando il benessere emotivo e relazionale.
          </p>
        </div>

        <div className="card-specialization">
          <h2 className="title-card">
            Psicoterapia per Persone con Disabilità Cognitive
          </h2>
          <p className="description-card-spe">
            Adattamenti terapeutici per sostenere il benessere emotivo di
            persone con disabilità cognitive.
          </p>
        </div>

        <div className="card-specialization">
          <h2 className="title-card">Costellazioni Familiari</h2>
          <p className="description-card-spe">
            Esplorazione delle dinamiche familiari per comprendere e risolvere
            conflitti, promuovendo l'armonia.
          </p>
        </div>

        <div className="card-specialization">
          <h2 className="title-card">Psicoterapia Transpersonale</h2>
          <p className="description-card-spe">
            Esplorazione della dimensione spirituale per favorire la crescita
            personale e la trasformazione interiore.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Specializations;
