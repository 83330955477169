import React from "react";
import { Link } from "react-scroll";
import "../styling/Footer.css";
import FooterLogo from "../assets/footer.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="group-frame-footer">
        <div className="footer-1">
          <Link to="home" spy={true} smooth={true} duration={500}>
            <img src={FooterLogo} alt="" className="footer-logo" />
          </Link>
        </div>
        <div className="footer-2">
          <h3 className="home-button-footer">
            {" "}
            <Link to="home" spy={true} smooth={true} duration={500}>
              Home
            </Link>
          </h3>
          <h4>
            {" "}
            <Link to="about" spy={true} smooth={true} duration={500}>
              Su di me
            </Link>
          </h4>
          <h4>
            {" "}
            <Link to="services" spy={true} smooth={true} duration={500}>
              Servizi
            </Link>{" "}
          </h4>
          <h4>
            {" "}
            <Link to="specializations" spy={true} smooth={true} duration={500}>
              Ambiti
            </Link>
          </h4>
          <h4>
            {" "}
            <Link to="contact" spy={true} smooth={true} duration={500}>
              Contatti
            </Link>{" "}
          </h4>
        </div>
        <div className="footer-3">
          <h3 className="social-button-footer">Social</h3>
          <h4>
            {" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/p/Luigi-Carlo-Collivasone-Psicologo-Psicoterapeuta-100057136963827/?locale=en_GB&paipv=0&eav=Afb4uN0RzdOTDSaCdxWjYlDuOZqM4uYn7OZQZcY-JN1Oelj3rQ94eZP8FvL535IevmI&_rdr">
              {" "}
              Facebook{" "}
            </a>{" "}
          </h4>
          <h4>
            {" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/lcc_psicologo_psicoterapauta/">
              {" "}
              Instagram{" "}
            </a>{" "}
          </h4>
          <button className="button-contact-footer">Contattami</button>
        </div>
      </div>
      <h2 className="upvisionmedia-mark">
        builded by{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.upvisionmedia.com">
          {" "}
          <span className="upvisionMedia-footer">upvisionMedia</span>{" "}
        </a>
      </h2>
    </div>
  );
};

export default Footer;
