import React from "react";
import "../styling/Home.css";
import Foto from "../assets/foto.png";
import Instagram from "../assets/instagram.png";
import Facebook from "../assets/facebook.png";
import CookieConsent from "react-cookie-consent";

const Home = () => {
  return (
    <div className="home-container">
      <div className="left-side">
        <div class="header-details">
          <div class="title">
            <div class="ciao-sono-luigi-carlo-collivasone">
              <span>
                <span class="ciao-sono-luigi-carlo-collivasone-span">
                  Ciao, sono
                  <br />
                </span>
                <span class="ciao-sono-luigi-carlo-collivasone-span2">
                  <br />
                  Luigi Carlo Collivasone
                </span>
              </span>
            </div>
          </div>
          <div class="proffession">
            <h1 class="psicologo-e">Psicologo e</h1>
            <div class="psicoterapeuta">
              <span>
                <h2 class="psicoterapeuta-span">
                  Psicoterapeuta<span class="psicoterapeuta-span2">.</span>
                </h2>
              </span>
            </div>
          </div>
          <div class="description">
            Specializzato in disturbi dell'umore, depressione, ansia, attacchi
            di panico, disturbi alimentari e dipendenze, integro la mindfulness
            e l'ipnosi ericksoniana per promuovere il benessere mentale nei miei
            pazienti.
          </div>
          <div className="social-media">
            <a href="https://www.facebook.com/p/Luigi-Carlo-Collivasone-Psicologo-Psicoterapeuta-100057136963827/?locale=en_GB&paipv=0&eav=Afb4uN0RzdOTDSaCdxWjYlDuOZqM4uYn7OZQZcY-JN1Oelj3rQ94eZP8FvL535IevmI&_rdr">
              <img src={Facebook} alt="" />
            </a>
            <a href="https://www.instagram.com/lcc_psicologo_psicoterapauta/">
              <img src={Instagram} alt="" />
            </a>
          </div>
          <div class="cv-btn">
            <div class="rectangle-3800">
              {" "}
              <a href="#contact" class="contattami">
                Contattami
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="right-side">
        <img
          className="profile-picture"
          src={Foto}
          alt="personale portrait of Luigi Carlo Collivasone"
        />
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="myCookieConsentCookie"
        style={{ background: "#315784" }}
        buttonStyle={{
          backgroundColor: "black",
          color: "#E4C49D",
          fontSize: "15px",
        }}
        expires={150}>
        Questo sito web utilizza i cookie per migliorare l'esperienza
        dell'utente.
      </CookieConsent>
    </div>
  );
};

export default Home;
