import "./App.css";
import NavbarComponent from "./components/NavbarComponent";
import Home from "./pages/Home.jsx";
import About from "./pages/About.jsx";
import Services from "./pages/Services.jsx";
import Specializations from "./pages/Specializations.jsx";
import Contact from "./pages/Contact.jsx";
import Footer from "./components/Footer.jsx";

function App() {
  return (
    <div className="App">
      
      <NavbarComponent />
      <div className="section" id="home">
        <Home />
      </div>
      <div className="section" id="about">
        <About />
      </div>
      <div className="section" id="services">
        <Services />
      </div>
      <div className="section" id="contact">
        <Contact />
      </div>
      <div className="section" id="specializations">
        <Specializations />
      </div>

      <Footer />
    </div>
  );
}

export default App;
