import React from "react";
import "../styling/Services.css";

import Psico1 from "../assets/psico1.png";
import Psico2 from "../assets/psico2.png";
import Psico3 from "../assets/psico3.png";
import Psico4 from "../assets/psico4.png";
import Psico5 from "../assets/psico5.png";

const Services = () => {
  return (
    <div class="container-services">
      <div class="services-heading">
        <span>
          <span class="servizi-span">
            Servizi<span class="servizi-span2">.</span>
          </span>
        </span>
        <br />
        <span class="ecco-in-che-modo-posso-aiutar-ti-span">
          Ecco in che modo posso aiutar
          <span class="ecco-in-che-modo-posso-aiutar-ti-span2">TI.</span>
        </span>
      </div>
      <div class="row">
        <div class="card">
          <img src={Psico1} className="icon" alt="card icon" />
          <div class="card-header">
            <h1>Psicoterapia individuale e di coppia</h1>
          </div>
          <div class="card-body">
            <p>
              Un percorso di crescita personale e connessione condivisa,
              la psicoterapia individuale e di coppia riflette un&#039;arte
              raffinata nel coltivare benessere emotivo e relazioni armoniose.
            </p>
          </div>
        </div>

        <div class="card middle">
          <img src={Psico2} className="icon" alt="card icon" />
          <div class="card-header">
            <h1>
              Valutazione
              <br />
              Psicologica
            </h1>
          </div>
          <div class="card-body">
            <p>
              Esplorazione profonda della mente attraverso l’analisi
              specialistica, la valutazione Psicologica rivela sfumature e
              risorse, illuminando percorsi di crescita e benessere individuale.
            </p>
          </div>
        </div>

        <div class="card middle">
          <img src={Psico3} className="icon" alt="card icon" />
          <div class="card-header">
            <h1>Psicoterapia a distanza</h1>
          </div>
          <div class="card-body">
            <p>
              Possiamo intraprendere un percorso o accedere a una consulenza
              tramite consuete piattaforme adoperate nell’ambito dello smart
              working, quali Zoom, Skype, Google Meet oppure videochiamate
              tramite Whatsapp.
            </p>
          </div>
        </div>
        <div class="card middle">
          <img src={Psico4} className="icon" alt="card icon" />
          <div class="card-header">
            <h1>Metodo Simonton</h1>
          </div>
          <div class="card-body">
            <p>
              Il Metodo Simonton, sviluppato dal dottor Carl Simonton, integra
              psicologia e medicina, focalizzandosi sulla visualizzazione
              creativa per favorire la guarigione e migliorare la qualità della
              vita.
            </p>
          </div>
        </div>

        <div class="card">
          <img src={Psico5} className="icon" alt="card icon" />
          <div class="card-header">
            <h1>Psicologia Forense</h1>
          </div>
          <div class="card-body">
            <p>
              Esploriamo la psiche con raffinatezza e dedizione, illuminando la
              verità nei contesti legali. Il nostro servizio di psicologia
              forense offre chiarezza con saggezza e competenza.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
